/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-item {
    --ion-item-background: transparent;
}

ion-header{
  background: black;
}

.color-app{
  color: white;
}

.background-app{
  background: white;
}

.list-md{
  background: black;
}

  // backgrounds //
.bg-white {
  background-color: #fff;
}
  
  .bg-light {
    background-color: var(--ion-color-light);
  }
  
  .bg-dark {
      background-color: var(--ion-color-dark);
  }
  
  .bg-primary {
      background-color: var(--ion-color-primary);
  }
  
  .bg-secondary {
      background-color: var(--ion-color-secondary);
  }
  
  .bg-tertiary {
      background-color: var(--ion-color-tertiary);
  }
  
  .bg-profile {
    background: var(--ion-color-primary);
  }
  
  .bg-main {
      background-image: linear-gradient(-135deg, var(--ion-color-medium), var(--ion-color-light))
  }
  
  // Helpers //
  .w100 {
    width: 100%;
  }
  
  .bold,
  .fw500 {
      font-weight: 500 !important;
  }
  
  .fw700 {
      font-weight: 700 !important;
  }
  
  .text08 {
    font-size: .8rem;
  }

  .text1 {
    font-size: 1rem !important;
  }

  .text12 {
    font-size: 1.2rem;
  }

  .text15 {
    font-size: 1.5rem;
  }
  
  .text20 {
    font-size: 2rem;
  }
  
  .text-white {
      color: #fff;
  }
  
  .text-primary {
      color: var(--ion-color-primary);
  }

  .text-secondary {
    color: var(--ion-color-secondary);
}
  
  .text-dark {
    color: var(--ion-color-dark);
  }

  .text-center{
    text-align: center !important;
  }

  .text-left{
    text-align: left !important;
  }

  .text-right{
    text-align: right !important;
  }

  // menu //
  // Menu user profile
  ion-toolbar.user-profile {
  
    .user-avatar {
      width: 68px;
      height: 68px;
    }
    .item-inner {
      border-width: 0;
    }
  }

  // Android card shadow
  ion-card {
    &.sc-ion-card-md-h {
      box-shadow: 0 2px 4px rgba(var(--ion-color-dark-rgb),.24);
    }
  }
  
  // style for auth pages
  .auth-page {
    background-image: linear-gradient(135deg, var(--ion-color-dark), var(--ion-color-primary));
  
    .grid {
      padding: 0;
      .col {
        padding: 0;
      }
    }
  
    .btn-group {
      .button-ios,
      .button-md {
        border-radius: 0;
        &:first-of-type {
          border-radius: 2px 0 0 2px;
        }
        &:last-of-type {
          border-radius: 0 2px 2px 0;
        }
      }
    }
  
    .auth-content {
      margin: 0 5%;
  
      .logo {
        width: 60px;
        height: 60px;
        margin: 20px auto 8px;
        border-radius: 10%;
        background: url("/assets/img/ionic4start-ico.png") no-repeat;
        background-size: 100%;
      }
  
      .list-form {
        padding: 0;
        margin-bottom: 0;
  
        ion-item {
          background: none;
          padding: 0;
  
          &:first-child {
            border-top: none;
          }
  
          &:after {
            display: none;
          }
  
          ion-label {
            font-size: 1em;
            color: var(--ion-color-light);
          }
  
          input {
            color: var(--ion-color-light);
          }
        }
      }
    }
  
  }
  
  // Custom Menu styles (overriding Shadow DOM)
  ion-menu {
    ion-header {
      ion-toolbar {
        &.ion-color-dark {
          --ion-color-base: linear-gradient(135deg, var(--ion-color-dark), var(--ion-color-primary)) !important;
        }
      }
    }
  }
  
  .mto {
    margin-top: 12px;
    ion-icon {
      margin-bottom: -2px;
    }
  }


  ////////////////////////////////
  // Clases propias
  ///////////////////////////////

  .c-primary{
    color: var(--ion-color-primary);
  }
  .c-danger{
    color: var(--ion-color-danger);
  }
  .c-success{
    color: var(--ion-color-success);
  }

  .footer-btn{
    background: var(--ion-color-primary);
    text-align: center;
    &.md{
        color: var(--ion-color-primary-contrast);
    }
    &.disable{
      background: var(--ion-color-light);
      color: var(--ion-color-medium);
    }
  }

  .swiper-pagination{
    &.slide-pagination-div-adbullets{
        // clase original: .slide-pagination-div-ad
        bottom: 25px !important;
        width: 100%;
    }
}

  .action-sheet-cancelar.sc-ion-action-sheet-md{
    color: var(--ion-color-danger) !important;
  }

  ion-alert{
    .input-label{
        pointer-events: none !important;
        border-bottom: none !important;
        color: var(--ion-color-primary) !important;
        font-weight: bold !important;
        &.sc-ion-alert-ios{
          background: transparent;
          border: none;
        }
    }
    .input-hidden{
      display: none;
    }
  }

  .card-div{
    padding: 5px 10px;
    margin: 5px;
    border-radius: 10px;
    background: var(--ion-color-medium);
    color: var(--ion-color-medium-contrast);
    &.warning{
      background: var(--ion-color-warning);
      color: var(--ion-color-warning-contrast);
    }
    &.danger{
      background: var(--ion-color-danger);
      color: var(--ion-color-danger-contrast);
    }
    &.success{
      background: var(--ion-color-success);
      color: var(--ion-color-success-contrast);
    }
  }

  cursor{
    cursor: pointer;
  }

  // Pantallas movil pequeñas (Ej.: Iphone 5)
@media (max-width: 330px){}

.alert-ads-date-data .alert-wrapper, .alert-ads-ticket-data .alert-wrapper {
    overflow: scroll !important;
}

@media (min-width: 720px){
  .container{
    padding-left: 20%;
    padding-right: 20%;
    background: var(--ion-color-medium);
  }

  .modal-fullscreen {
    .modal-wrapper {
        position: absolute;
        /* top: 0;
        left: 0; */
        display: block;
        width: 80%;
        height: 80%;
    }
  }
}